.AdminHeader
{
    background-color: red;
    display: flex;
    justify-content: space-between;
    direction: rtl;
    padding: 20px;
    background: #ff8400;
    background-image: -webkit-linear-gradient(232.56deg, #ff8400 0%, #d97000 100%);
    background-image: linear-gradient(232.56deg, #ff8400 0%, #d97000 100%);
    box-sizing: border-box;

    .AdminHeaderTitle
    {
        color: #fff;
        font-size: 20px;
    }

    .AdminHeaderLoggoutBtn
    {
        border: none;
        outline: none;
        background-color: transparent;
        color: #fff;
        cursor: pointer;
        font-size: 16px;
        padding: 0px 10px;
        transition: .2s ease-in-out;
    }

    .AdminHeaderLoggoutBtn:hover
    {
        color: #ff8400;
        background-color: #fff;
        border-radius: 5px;
    }
}