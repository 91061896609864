.header {
	display: flex;
	background-color: #ff8400;
	padding: 5px 50px;
	height: 50px;
	direction: rtl;
	align-items: center;

	.header-logo {
		height: 100%;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-decoration: none;
		transition: 0.2s ease-in-out;
		margin-left: 30px;

		.logo-first-line {
			line-height: 1;
			margin: 0;
			padding: 0;
			color: #fff;
			text-align: center;
			display: block;
			overflow: hidden;
			height: 16px;
			text-shadow: 1px 1px #7d7d7d;
			animation: SlideUp 1s ease-in-out;
		}

		@keyframes SlideUp {
			from {
				height: 0;
			}
			to {
				height: 16px;
			}
		}

		.logo-hr {
			line-height: 1;
			margin: 0;
			padding: 0;
			height: 1px;
			border-radius: 50%;
			width: 100%;
			background-color: #fff;
			box-shadow: 0px 0px 1px 0.5px #7d7d7d;
			border: none;
			animation: OpacityChange 1s ease-in-out;
		}

		.logo-second-line {
			line-height: 1;
			margin: 0;
			padding: 0;
			color: #fff;
			text-align: center;
			display: block;
			width: 100%;
			overflow: hidden;
			white-space: pre;
			text-shadow: 1px 1px #7d7d7d;
			animation: SlideLeft 1s ease-in-out;
		}

		@keyframes SlideLeft {
			from {
				width: 0;
			}
			to {
				width: 100%;
			}
		}
	}

	.header-logo:hover {
		transform: scale(1.1);
	}

	.routes.mobile {
		display: none;
	}

	.routes.desktop {
		display: flex;
		direction: rtl;
		align-items: center;

		.nav-route {
			padding: 3px 40px;
			background-color: transparent;
			font-size: 1.2rem;
			font-weight: 700;
			color: #fff;
			text-decoration: none;
			border-bottom: 3px solid transparent;
			transition: 0.3s ease-in-out;
			margin: 0px 5px;
		}

		.nav-route:hover {
			border-bottom: 3px solid #fff;
		}
	}
}

@media only screen and (max-width: 767px) {
	.header {
		padding: 5px 10px;
		.routes.desktop {
			display: none;
		}

		.routes.mobile {
			flex: 1;
			display: flex;
			align-items: center;
			direction: ltr;

			.menu-button {
				background-color: transparent;
				outline: transparent;
				border: 1px solid white;
				width: 45px;
				height: 45px;
				border-radius: 5px;
				color: #fff;
				font-size: x-large;
				justify-content: center;
				display: flex;
				align-items: center;
			}

			.routes-menu {
				position: fixed;
				background-color: rgba(0, 0, 0, 0.9);
				width: 100%;
				height: 100%;
				z-index: 1000;
				left: 0;
				top: 0;
				width: 0px;
				overflow: hidden;
				transition: 0.2s ease-in-out;

				.close-button {
					float: left;
					margin: 5px 15px;
					font-size: xx-large;
					padding: 0px;
					background-color: transparent;
					border: none;
					color: #fff;
					outline: none;
				}

				.menu-title {
					margin-right: 20px;
					font-weight: 300;
					font-size: 1.5rem;
					margin-top: 15px;
					color: #ff8400;
					direction: rtl;
				}

				.menu-title::after {
					height: 1px;
					content: '';
					background: white;
					display: block;
					width: 120px;
				}

				.nav-route {
					display: block;
					width: 100%;
					text-align: center;
					font-size: x-large;
					color: #fff;
					text-decoration: none;
					border-bottom: 1px solid #444257;
					padding: 10px 0px;
				}
			}

			.routes-menu.show {
				width: 100%;
			}
		}
	}
}
