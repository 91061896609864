.contact-us {
	margin: 50px auto;
	.contact-us-title {
		text-align: center;
		font-weight: 600;
		font-size: xx-large;
        text-decoration: underline;
        text-decoration-color: #ff8400;
	}

	.contact-flex {
		display: flex;
		justify-content: space-around;

		.contact-box {
			margin: 10px;
			padding: 20px 50px;
			text-align: center;
			color: #8e8e8e;

			i {
				font-size: 50px;
				color: #8e8e8e;
				margin-bottom: 20px;
				text-shadow: #000 0px 1px 3px;
			}

			.box-title {
				font-weight: 600;
				font-size: 20px;
				margin: 0;
			}

			.box-value {
				margin: 0;
				font-size: 18px;
				color: #000;
			}
		}
	}
}

@media only screen and (max-width: 767px) {
	.contact-us {
		.contact-flex {
            flex-wrap: wrap;
		}
	}
}
