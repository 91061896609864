.footer {
	display: flex;
	align-items: center;
	background-color: #ff8400;
	height: 70px;

	.footer-content {
        margin: auto;

		.footer-text {
            color: #fff;
		}
	}
}
