.page-content {
	.home-header {
		display: flex;
		box-sizing: border-box;

		.left {
			width: 40vw;
			background-image: url('../../images/home_header.png');
			background-blend-mode: multiply;
			background-repeat: no-repeat;
			background-color: transparent;
			background-size: cover;
			background-position: 100% 100%;
			min-height: 30vw;
			max-height: 400px;
			padding-left: 200px;
			z-index: 100;
			max-width: 100%;
		}

		.right {
			flex: 1;
			box-sizing: border-box;
			padding-left: 150px;
			width: 100%;
			margin-left: -320px;
			margin-top: 50px;
			overflow: hidden;

			.company-name {
				background-color: #ff8400;
				height: fit-content;

				.logo-title {
					margin: auto;
					width: fit-content;
					padding: 0px 20px;
					padding-top: 30px;
					overflow: hidden;
					border-bottom: 2px solid #000;
					line-height: 1.5em;
					font-size: 40px;
					text-shadow: 1px 1px #000;
					color: #fff;
					animation: SlideDown 1.5s ease-in-out;

					@keyframes SlideDown {
						from {
							height: 0px;
						}
						to {
							height: 1.5em;
						}
					}
				}

				.logo-title2 {
					margin: auto;
					text-align: center;
					width: fit-content;
					line-height: 1.5em;
					padding-bottom: 35px;
					text-shadow: 1px 1px #000;
					font-size: x-large;
					color: #fff;
					animation: OpacityChange 2s ease-in-out;

					@keyframes OpacityChange {
						from {
							opacity: 0;
						}
						to {
							opacity: 1;
						}
					}
				}
			}

			.register-btn {
				background-color: #7d7d7d;
				border: none;
				padding: 5px 70px;
				color: white;
				font-size: 1.3rem;
				border-radius: 5px;
				outline: none;
				font-weight: 700;
				margin: auto;
				display: block;
				margin-top: 20px;
				width: 300px;
				cursor: pointer;
				transition: 0.2s ease-in-out;
			}

			.register-btn:hover {
				background-color: #ff8400;
			}
		}
	}

	.our-work {
		margin-bottom: 40px;

		.our-work-title {
			text-align: center;
			font-size: 2.5rem;
			margin: 0px;
		}

		.our-work-flex {
			display: flex;
			direction: rtl;
			justify-content: center;
			flex-wrap: wrap;

			.our-work-box {
				display: flex;
				flex-direction: column;
				background-size: cover;
				background-blend-mode: multiply;
				background-color: rgba(0, 0, 0, 0.6);
				width: 255px;
				max-width: 100%;
				height: 350px;
				align-items: center;
				justify-content: center;
				padding: 30px 0px;
				border-radius: 5px;
				cursor: pointer;
				margin: 10px;
				box-sizing: border-box;
				transition: 0.2s ease-in-out;

				.icon {
					margin: 10px auto;
					color: #fff;
					background-color: rgba(255, 132, 0, 0.6);
					border-radius: 15px;
					width: 70px;
					height: 70px;
					display: flex;
					align-items: center;
					margin: auto;
					justify-content: center;
					font-size: xx-large;
					transition: 0.5s ease-in-out;
				}

				.box-label {
					text-align: center;
					line-height: 0.9;
					font-size: 22px;
					font-weight: 900;
					color: #fff;
				}

				.box-description {
					height: 0px;
					overflow: hidden;
					margin: 20px;
					line-height: 1.2em;
					color: #fff;
					text-align: center;
					text-shadow: 1px 1px black;
					transition: 0.5s ease-in-out;
				}
			}
			.our-work-box:hover {
				background-color: rgba(255, 132, 0, 0.6);

				.box-description {
					height: 100%;
				}

				.icon {
					background-color: transparent;
				}
			}

			.our-work-box.se3od {
				background-image: url('../../images/se3od.png');
			}

			.our-work-box.mef3al {
				background-image: url('../../images/mef3al.jpg');
				background-position: 50%;
			}

			.our-work-box.yetsor {
				background-image: url('../../images/yetsor.jpg');
			}

			.our-work-box.nekyon {
				background-image: url('../../images/nekyon.jpg');
			}

			.our-work-box.malon {
				background-image: url('../../images/malon.jpg');
				background-position: 50%;
			}
		}
	}

	.fill-data {
		padding: 50px;
		border-radius: 10px;
		border: 2px solid rgba(128, 128, 128, 0.3);
		box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
		width: fit-content;
		margin: auto;
		text-align: center;
		margin-bottom: 50px;
		max-width: 95%;
		box-sizing: border-box;

		.form-title {
			font-weight: 700;
			font-size: 24px;
			text-align: center;
			line-height: 1.2em;
			margin: 0px;
			margin-bottom: 20px;
		}

		.fill-data-form {
			display: flex;
			flex-direction: column;
			align-items: center;

			.input-style {
				background-color: #e0e0e0;
				border: 1px solid #c9c9c9;
				border-radius: 5px 5px 5px 5px;
				color: #555;
				display: block;
				font-size: 16px;
				height: 52px;
				margin-bottom: 15px;
				outline: none;
				padding: 0px 15px;
				transition: 0.2s ease-in-out;
				direction: rtl;
				width: 400px;
				max-width: 100%;
			}

			.input-style.error
			{
				border: 2px solid red;
			}

			.error-message {
				color: red;
			}

			.submit-button {
				background-color: #ff8400;
				border: none;
				padding: 5px 70px;
				color: white;
				font-size: 1.3rem;
				border-radius: 5px;
				outline: none;
				font-weight: 700;
				cursor: pointer;
				transition: 0.2s ease-in-out;
			}

			.submit-button:hover {
				background-color: #d97000;
			}
		}
	}
}

@media only screen and (max-width: 767px) {
	.page-content {
		.home-header {
			display: flex;
			flex-direction: column;

			.left {
				width: 100vw;
				height: 50vw;
				background-position: 0%;
				background-size: contain;
				padding-left: 0px;
				z-index: 100;
				margin: 0px;
				background-position: 0% 0%;
				padding: 0px;
			}

			.right {
				width: 100%;
				margin: -15vw 0px;
				margin-bottom: 10vw;
				padding: 0px;
				padding-top: 10vw;
			}
		}

		.our-work {
			.our-work-title {
				font-size: 1.5rem;
			}
		}

		.fill-data {
			.form-title {
				font-size: 1.2rem;
			}
		}
	}
}
