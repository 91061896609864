
.Admin
{
    .buttons_parent
    {
        display: flex;

        .admin-button
        {
            margin: 0px 5px;
            cursor: pointer;
            background-color: #168a8a;
            color: white;
            outline: none;
            border: 1px solid transparent;
            transition: .2s ease-in-out;
        }

        .admin-button:hover
        {
            border: 1px solid #168a8a;
            background-color: white;
            color: #168a8a;
        }
    }
}