.page-content
{
    margin: 0 auto;

    .register-form
    {
        width: 90%;
        max-width: 1200px;
        background-color: #fff;
        margin: 50px auto;
        box-sizing: border-box;
        padding: 30px;
        border: 2px solid #ff8400;
        box-shadow: 0 -5px 0 0 rgb(147 149 152 / 20%);
        min-height: 440px;

        .form-title
        {
            color: #ff8400;
            text-align: center;
            font-weight: 300;
            font-size: 2.5rem;
            margin-top: 0px;
            margin-bottom: 0.5rem;
        }

        .form-title-small-content
        {
            text-align: center;
            direction: rtl;
        }

        .form-title-small
        {
            color: black;
            font-weight: 300;
            font-size: 1.25rem;
            margin-top: 0px;
            margin-bottom: 0.5rem;
            margin: auto 5px;
        }

        .alert-message
        {
            color: red;
            text-align: center;
        }

        .row
        {
            direction: rtl;
            margin-bottom: 20px;

            .row-title
            {
                margin-bottom: 0;
                box-sizing: border-box;
                font-size: 16px;
                font-weight: 300;
                color: #212529;
            }

            .row-title[mandatory=true]::after
            {
                content: '*';
                color: red;
            }

            .row-input
            {
                width: 100%;
                border: 2px solid #d7d7d7;
                box-shadow: inset 0px 1px 5px 0 rgb(0 0 0 / 10%);
                height: 43px;
                line-height: 43px;
                font-size: 1rem;
                padding: 0 10px;
                box-sizing: border-box;
                color: #495057;
                background-color: #fff;
                border-radius: 3px;
                transition: all 0.3s cubic-bezier(.33, .07, .23, .86);
                outline: none;
            }

            .row-input.error
            {
                border: 2px solid red;
            }

            .row-input:disabled
            {
                background-color: #e9ecef;
            }

            .row-input:focus
            {
                border: 2px solid #d97000;;
            }
        }

        .online-service
        {
            direction: rtl;
            color: #ff8400;
            cursor: pointer;
            width: fit-content;
            margin: auto 0 auto auto;
            display: block;
            user-select: none;
        }

        .form-buttons
        {
            display: block;
            margin: 0px auto;
            text-align: center;
            margin-top: 50px;

            .form-submit-btn
            {
                background: linear-gradient(-232.56deg, var(--c2, --c2), var(--c1, --c1) 51%, var(--c2, --c2)) var(--x, 0)/200%;
                --c1: #ff8400;
                --c2: #d97000;
                color: #fff;
                font-size: 14px;
                line-height: 1.5;
                padding: 0.5rem 1.6rem;
                border: none;
                cursor: pointer;
                box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
                border-radius: 0.125rem;
                outline: none;
                margin: 0px 10px;
            }
    
            .form-submit-btn:hover
            {
                --c1: #d97000;
                --c2: #ff8400;
            }
        }
    }
}

input, button, textarea, select
{
    font-family: 'Cairo', sans-serif;
}

.input-text, .input-textarea, .input-select
{
    display: block;
    width: 100%;
    height: 50px;
    margin-bottom: 15px;
    color: #000;
    border: 1px solid #f1f1f1;
    background-color: #e4e4e4;
    font-size: 16px;
    border-radius: 5px 5px 5px 5px;
    outline: none;
    padding-right: 15px;
    box-sizing: border-box;
    -webkit-transition: .2s ease-in-out;
    -o-transition: .2s ease-in-out;
    transition: .2s ease-in-out;
    box-sizing: border-box;
}

.input-text.error
{
    border: 2px solid red; 
}

.input-select
{
    width: 100%;
}

.input-textarea
{
    height: auto;
    resize: none;
}

.input-text:focus, .input-textarea:focus, .input-select:focus
{
    border: 1px solid grey;
}

.form-button
{
    background: -webkit-linear-gradient(#b18e69, #FED07D);
    border: none;
    color: #000;
    letter-spacing: 1px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    width: 100%;
    padding: 10px 0;
    border-radius: 3px;
    -webkit-transition: all .20s ease-in-out;
    -o-transition: all .20s ease-in-out;
    transition: all .20s ease-in-out;
}

.form-button:hover
{
    box-shadow: 0 0 5px 0 #B7987A, 0 2px 5px 0 #B7987A;
}

input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button
{ 
    -webkit-appearance: none; 
     margin: 0; 
}

button:disabled
{
    box-shadow: 0 0 5px 0 #B7987A, 0 2px 5px 0 #B7987A;
}

button:disabled:hover
{
    background-color: white;
}